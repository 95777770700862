import classnames from 'classnames';
import { createElement, FC, useEffect, useState } from 'react';
import { Swiper as SwiperType, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Zoom } from '../../zoom/zoom';
import {
  Diagram,
  useActiveDiagram,
  useChangeActiveDiagram,
} from '../activeDiagramContext/activeDiagramContext';
import { IDiagram, ItemText } from '../diagramsSlide/diagramsSlide';
import styles from './mobileSlider.module.scss';

export const MobileSlider: FC<{
  diagrams: IDiagram[];
  selectedType: Diagram;
}> = ({ diagrams, selectedType }) => {
  const onChange = useChangeActiveDiagram();
  const activeDiagram = useActiveDiagram();
  const [swiperHeader, setSwiperHeader] = useState<SwiperType | null>(null);
  const [swiperContent, setSwiperContent] = useState<SwiperType | null>(null);
  const [activeIndex, setActiveIndex] = useState(
    diagrams.findIndex((i) => i.name === activeDiagram),
  );

  useEffect(() => {
    swiperHeader !== null && swiperHeader.slideTo(activeIndex, 0);
  }, [activeIndex, swiperHeader]);

  useEffect(() => {
    const selectedIndex = diagrams.findIndex(
      (value) => value.type === selectedType,
    );
    setActiveIndex(selectedIndex);
  }, [selectedType, diagrams]);

  useEffect(() => {
    if (
      swiperContent &&
      !swiperContent.destroyed &&
      swiperHeader &&
      !swiperHeader.destroyed
    ) {
      swiperHeader.thumbs.swiper = swiperContent;
    }
  }, [swiperContent, swiperHeader]);

  if (activeIndex < 0) {
    return null;
  }

  return (
    <div className={styles.diagrams__menu_slider}>
      <Swiper
        autoplay={{ delay: 3000 }}
        centeredSlides={true}
        slidesPerView='auto'
        touchRatio={0.6}
        slideToClickedSlide={true}
        spaceBetween={32}
        modules={[Thumbs]}
        thumbs={swiperContent ? { swiper: swiperContent } : void 0}
        watchSlidesProgress={true}
        onSwiper={setSwiperHeader}
        onDestroy={() => setSwiperHeader(null)}
      >
        {diagrams.map((item, i) => (
          <SwiperSlide
            key={item.name}
            className={styles.diagrams__menu_slider_header}
          >
            <div
              className={classnames(
                styles.diagrams__menu_slider_name,
                activeIndex === i && styles.diagrams__menu_slider_name_active,
              )}
            >
              {item.name}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        loop={true}
        slidesPerView={1}
        watchSlidesProgress={true}
        autoHeight={true}
        onSwiper={(s) => setSwiperContent(s as any)}
        onSlideChange={(e) => {
          setActiveIndex(e.realIndex);
          onChange(diagrams[e.realIndex].type);
        }}
        onDestroy={() => setSwiperContent(null)}
        initialSlide={activeIndex}
      >
        {diagrams.map((item, i) => (
          <SwiperSlide key={item.name}>
            <div
              key={item.type}
              className={styles.diagrams__menu_slider_content}
            >
              <h3
                className={classnames(
                  styles.content__header,
                  styles.diagrams__menu_slider_title,
                )}
              >
                {item.title}
              </h3>
              {item.svg &&
                createElement(item.svg, {
                  className: classnames(
                    styles.content__svg,
                    styles.diagrams__menu_slider_img,
                  ),
                })}
              {item.image && (
                <Zoom>
                  <img
                    src={item.image}
                    className={classnames(
                      styles.content__img,
                      styles.diagrams__menu_slider_img,
                    )}
                    alt={item.name}
                  />
                </Zoom>
              )}
              <ItemText text={item.text} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
