import classnames from 'classnames';
import { FC, MouseEvent, MouseEventHandler, useCallback } from 'react';

import { scrollToView } from '../../../utils/scrollToView';
import {
  Diagram,
  useChangeActiveDiagram,
} from '../activeDiagramContext/activeDiagramContext';
import styles from './productsSlide.module.scss';

const getCircleElement = (event: MouseEvent<SVGGElement, Event>) => {
  let circle = event.target as SVGCircleElement;
  if (circle.tagName !== 'CIRCLE') {
    circle = (circle.tagName === 'G' ? circle : circle.parentElement)
      ?.getElementsByTagName('circle')
      .item(0) as SVGCircleElement;
  }

  return circle;
};

const onHover: MouseEventHandler<SVGGElement> = (event) => {
  const circle = getCircleElement(event);
  circle.setAttribute('filter', 'url(#hover)');
};

const onBlur: MouseEventHandler<SVGGElement> = (event) => {
  const circle = getCircleElement(event);
  circle.removeAttribute('filter');
  circle.classList.remove(styles['svg__g_circle--active']);
};

const onMouseDown: MouseEventHandler<SVGGElement> = (event) => {
  const circle = getCircleElement(event);
  circle.classList.add(styles['svg__g_circle--active']);
};

const onMouseUp: MouseEventHandler<SVGGElement> = (event) => {
  const circle = getCircleElement(event);
  circle.classList.remove(styles['svg__g_circle--active']);
};

export const ProductsSlide: FC = () => {
  const onChange = useChangeActiveDiagram();
  const onClick: MouseEventHandler<SVGGElement> = useCallback(
    (event) => {
      let elem = event.target as SVGGElement;
      if (elem.tagName !== 'G') {
        elem = elem.parentElement as unknown as SVGGElement;
      }
      const id = elem.id as Diagram;
      onChange(id);
      scrollToView('slide_diagrams');
    },
    [onChange]
  );

  return (
    <div className={styles.slide}>
      <h2
        className={classnames('h2', styles.slide__header)}
        id='slide_products'
      >
        Product Family
      </h2>
      <div className={styles.slide__diagram}>
        <svg
          width='821'
          height='570'
          viewBox='0 0 821 580'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            cx='415'
            cy='281'
            r='266.5'
            stroke='#18212F'
            className={styles.svg__circle}
          />
          <circle
            cx='415'
            cy='281'
            r='244.5'
            stroke='#1B53A5'
            strokeLinecap='round'
            strokeDasharray='1 10'
            className={styles.svg__circle}
          />
          <g filter='url(#filter0_d_127_1890)'>
            <circle
              cx='415'
              cy='281'
              r='175'
              stroke='#1B53A5'
              strokeWidth='20'
              shapeRendering='crispEdges'
            />
          </g>
          <circle
            cx='415'
            cy='281'
            r='77.5'
            stroke='#18212F'
            className={styles.svg__circle}
          />
          <path
            d='M399.716 291.717L389 281.001L415 255V276.433L399.716 291.717Z'
            fill='url(#paint0_linear_127_1890)'
          />
          <path
            d='M407.98 283.451V299.982L399.716 291.715L407.98 283.451Z'
            fill='#1B53A5'
          />
          <path
            d='M430.283 270.282L441 280.998L415 307V285.567L430.283 270.282Z'
            fill='url(#paint1_linear_127_1890)'
          />
          <path
            d='M422.02 278.549V262.021L430.283 270.284L422.02 278.549Z'
            fill='#1B53A5'
          />

          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseUp={onMouseUp}
            onMouseDown={onMouseDown}
            id='EE'
          >
            <text x='348.663' y='386.894' className={styles.svg__g_text}>
              Exchange Edition
            </text>
            <circle
              cx='460.5'
              cy='341.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='460.5' y='347' className={styles.svg__g_text_center}>
              EX
            </text>
          </g>

          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            id='BE'
          >
            <text x='358.398' y='173.922' className={styles.svg__g_text}>
              Broker Edition
            </text>
            <circle
              cx='414.5'
              cy='204.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='414.5' y='210' className={styles.svg__g_text_center}>
              BR
            </text>
          </g>

          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            id='NFT'
          >
            <text x='294.469' y='281.969' className={styles.svg__g_text}>
              NFT
            </text>
            <circle
              cx='336.5'
              cy='294.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='336.5' y='300' className={styles.svg__g_text_center}>
              NFT
            </text>
          </g>

          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            id='SOR'
          >
            <text x='62.7362' y='69.537' className={styles.svg__g_text}>
              Smart Order Router
            </text>
            <circle
              cx='214.5'
              cy='104.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='214.5' y='109' className={styles.svg__g_text_center}>
              SOR
            </text>
          </g>

          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            id='MM'
          >
            <text x='11.72' y='317.969' className={styles.svg__g_text}>
              Market Maker
            </text>
            <circle
              cx='150.5'
              cy='317.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='150.5' y='323' className={styles.svg__g_text_center}>
              MM
            </text>
          </g>

          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            id='FIX'
          >
            <text x='618.741' y='480.075' className={styles.svg__g_text}>
              Fix Hub - Data & Trading
            </text>
            <circle
              cx='591.5'
              cy='449.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='591.5' y='455' className={styles.svg__g_text_center}>
              FIX
            </text>
          </g>
          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            id='TCA'
          >
            <text x='134.126' y='558.11' className={styles.svg__g_text}>
              Transaction Cost Analysis
            </text>

            <circle
              cx='335.5'
              cy='512.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='335.5' y='517' className={styles.svg__g_text_center}>
              TCA
            </text>
          </g>
          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            id='OTC'
          >
            <text x='502.991' y='10' className={styles.svg__g_text}>
              Otc Desk
            </text>
            <circle
              cx='492.5'
              cy='47.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='492.5' y='53' className={styles.svg__g_text_center}>
              OTC
            </text>
          </g>

          <g
            className={styles.svg__g}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            id='ST'
          >
            <text x='711.821' y='246.537' className={styles.svg__g_text}>
              Spread Trader
            </text>
            <circle
              cx='680.5'
              cy='246.5'
              r='9.5'
              className={styles.svg__g_circle}
            />
            <text x='680.5' y='252' className={styles.svg__g_text_center}>
              ST
            </text>
          </g>
          <defs>
            <filter
              id='filter0_d_127_1890'
              x='210'
              y='76'
              width='410'
              height='410'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset />
              <feGaussianBlur stdDeviation='10' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.105882 0 0 0 0 0.32549 0 0 0 0 0.647059 0 0 0 1 0'
              />
              <feBlend
                mode='normal'
                in2='BackgroundImageFix'
                result='effect1_dropShadow_127_1890'
              />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='effect1_dropShadow_127_1890'
                result='shape'
              />
            </filter>
            <filter
              id='hover'
              x='0'
              y='0'
              width='100%'
              height='100%'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset />
              <feGaussianBlur stdDeviation='10' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.105882 0 0 0 0 0.32549 0 0 0 0 0.647059 0 0 0 1 0'
              />
              <feBlend
                mode='normal'
                in2='BackgroundImageFix'
                result='effect1_dropShadow_168_3171'
              />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='effect1_dropShadow_168_3171'
                result='shape'
              />
            </filter>
            <linearGradient
              id='paint0_linear_127_1890'
              x1='386.229'
              y1='274.23'
              x2='413.84'
              y2='275.368'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='1.921e-05' stopColor='#3C62AE' />
              <stop offset='1' stopColor='#27C4F4' />
            </linearGradient>
            <linearGradient
              id='paint1_linear_127_1890'
              x1='443.77'
              y1='287.77'
              x2='416.16'
              y2='286.632'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='1.921e-05' stopColor='#3C62AE' />
              <stop offset='1' stopColor='#27C4F4' />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className={styles.slide__lines}>
        <svg fill='none' className={styles.lines} viewBox={`0 0 1500 320`}>
          <g filter='url(#filter0_d_1_38)' className={styles.lines__g}>
            <path
              d='M2500 234.385C2500 234.385 2387.57 100.057 2281.13 106.113C2164.25 112.764 2157.14 220.712 2061.76 192.566C2000.8 174.576 1944.83 373.671 1832.66 285.261C1785.22 247.878 1673.79 327.334 1630.81 234.385C1602.42 172.976 1550.57 158.085 1477.61 174.576C1404.66 191.066 1377.67 177.108 1302.22 141.094C1236.26 109.611 1203.27 190.393 1105.84 205.059C911 234.385 784.464 53.4592 718.569 25.0473C652.674 -3.36469 574.027 13.5663 489.625 86.2844C405.257 158.974 348.5 182.5 289.5 167.5C203.768 145.704 168.399 -39.307 0 34.1527'
              stroke='#1B53A5'
            />
          </g>
          <g filter='url(#filter1_d_1_38)' className={styles.lines__g}>
            <path
              d='M0 202.81C33.9796 255.981 100.44 312 222.367 312C284.599 312 359.597 266.097 437.951 202.81C535.894 123.702 622.124 139.089 664.102 223.049C683.905 262.659 753.548 293.51 815.511 211.555C890.697 112.11 1064.86 52.6425 1135.73 157.095C1185.74 230.806 1287.42 228.546 1365.45 117.541C1440.93 10.1658 1507.6 64.4585 1516.09 72.1317C1537.08 91.0891 1539.58 114.109 1604.54 128.601C1669.5 143.093 1727.96 92.1208 1783.43 156.086C1820.14 198.425 1904.43 151.208 1933.84 143.136C2002.3 118.802 2044.27 66.426 2178.19 143.136C2312.11 219.846 2497 72.1317 2497 72.1317'
              stroke='#27C4F4'
            />
          </g>
          <defs>
            <filter
              id='filter0_d_1_38'
              x='-14.5'
              y='0.747681'
              width='2529.46'
              height='326.508'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dy='4' />
              <feGaussianBlur stdDeviation='7' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.105882 0 0 0 0 0.32549 0 0 0 0 0.647059 0 0 0 1 0'
              />
              <feBlend
                mode='normal'
                in2='BackgroundImageFix'
                result='effect1_dropShadow_1_38'
              />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='effect1_dropShadow_1_38'
                result='shape'
              />
            </filter>
            <filter
              id='filter1_d_1_38'
              x='-29.0532'
              y='26.3804'
              width='2554.83'
              height='318.87'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dy='4' />
              <feGaussianBlur stdDeviation='14' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.152941 0 0 0 0 0.768627 0 0 0 0 0.956863 0 0 0 1 0'
              />
              <feBlend
                mode='normal'
                in2='BackgroundImageFix'
                result='effect1_dropShadow_1_38'
              />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='effect1_dropShadow_1_38'
                result='shape'
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
};
