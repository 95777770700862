import { useEffect, useState } from 'react';

export const useWindowResize = () => {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const resize = () => {
      setWidth(
        Math.min(globalThis.window.innerWidth, globalThis.window.outerWidth),
      );
    };
    globalThis.window.addEventListener('resize', resize);
    globalThis.window.addEventListener('orientationchange', resize);
    resize();

    return () => {
      globalThis.window.removeEventListener('resize', resize);
      globalThis.window.removeEventListener('orientationchange', resize);
    };
  }, []);

  return width;
};
