import classnames from 'classnames';
import { FC } from 'react';

import styles from './mainAnimation.module.scss';

const GRADIENT_WIDTH = '50%';
const PATH1 =
  'M0 143.371C0 143.371 13.4421 131.393 31.968 95.4236C83.4166 -4.46732 189.375 12.8389 243.601 84.5648C307.037 168.473 382.961 226.909 504.84 226.909C567.047 226.909 642.016 207.459 720.338 144.207C818.242 65.1418 917.925 99.2837 957.086 190.927C996.248 282.571 1069.15 286.779 1110.17 262.805C1151.19 238.83 1199.64 251.383 1220.54 302.337C1243.04 357.225 1372.17 373.749 1450.16 262.805C1525.61 155.487 1560.28 199.27 1597.91 223.272C1621.91 238.582 1636.71 254.21 1688.16 273.359C1739.6 292.507 1812.53 237.398 1867.98 301.328C1904.68 343.644 1987.35 303.105 2018.33 288.385C2155.34 223.272 2287.5 261.5 2320.52 331.295C2353.55 401.091 2467 399.145 2511.5 360.5C2556 321.855 2574 242.696 2634.5 233C2712.5 220.5 2750.5 317 2826 303C2896 290.02 2898.5 198.685 2956 204.5C3045 213.5 3015.41 365.65 3097.5 360.5C3122.4 358.938 3122.15 334.5 3145 334.5C3161.5 334.5 3182.48 390 3211.5 390C3288 390 3403 350 3482.5 368.5';
const PATH2 =
  'M0.999023 235.755C64.2984 263.185 147.189 248.552 233.766 179.852C344.156 92.2574 503.496 270.753 561.938 299.721C620.38 328.69 671.329 291.73 755.663 219.08C840.031 146.401 918.647 129.48 984.516 157.876C1050.38 186.273 1124.17 238.603 1198.94 180.035C1273.71 121.467 1353.82 55.7999 1421.47 64.6738C1489.12 73.5477 1515.82 96.6197 1533.62 119.692C1551.43 142.764 1565.67 128.566 1578.13 128.566C1590.59 128.566 1620.38 185.53 1662.34 179.852C1709.29 173.498 1816.68 193.337 1864.1 230.7C1908.09 265.369 1964.54 217.003 2053.95 194.167C2143.36 171.332 2166.8 257.398 2270.73 262.093C2381.96 267.118 2391.01 25.1685 2531.5 12.0001C2687.89 -2.65871 2725.7 262.093 2846.5 262.093C2997.5 262.093 3013.3 109.895 3140.5 119.692C3224.84 126.188 3248.9 221.1 3338.5 207.5C3428.1 193.9 3469.17 171.833 3478.5 162.5';

export const MainAnimation: FC = () => {
  return (
    <div className={styles.container}>
      <svg
        fill='none'
        className={styles.svg}
        viewBox={`0 0 1740 300`}
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <defs>
          <linearGradient id='Gradient'>
            <stop offset='0' stopColor='black' />
            <stop offset='1' stopColor='white' />
          </linearGradient>
          <mask id='mask1'>
            <rect
              y='-100%'
              x={'-' + GRADIENT_WIDTH}
              width={GRADIENT_WIDTH}
              height='200%'
              fill='url(#Gradient)'
            >
              <animateMotion
                dur='5s'
                keyPoints='0;1'
                keyTimes='0;1'
                calcMode='linear'
                repeatCount='indefinite'
              >
                <mpath xlinkHref='#path1' />
              </animateMotion>
            </rect>
          </mask>
          <mask id='mask2'>
            <rect
              y='-100%'
              x={'-' + GRADIENT_WIDTH}
              width={GRADIENT_WIDTH}
              height='200%'
              fill='url(#Gradient)'
            >
              <animateMotion
                dur='5s'
                keyPoints='0;1'
                keyTimes='0;1'
                calcMode='linear'
                repeatCount='indefinite'
              >
                <mpath xlinkHref='#path2' />
              </animateMotion>
            </rect>
          </mask>
          <filter
            id='fp1'
            x='-30.5532'
            y='-100'
            width='4000'
            height='500.703'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='14' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.152941 0 0 0 0 0.768627 0 0 0 0 0.956863 0 0 0 1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_127_2707'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_127_2707'
              result='shape'
            />
          </filter>
          <filter
            id='fp2'
            x='-30.671'
            y='-100'
            width='4000'
            height='500.865'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='7' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.105882 0 0 0 0 0.32549 0 0 0 0 0.647059 0 0 0 1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_127_2708'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_127_2708'
              result='shape'
            />
          </filter>
        </defs>
        <g className={styles.g1}>
          <path d={PATH1} className={styles.path1} id='path1' width={'100%'} />
          <path
            d={PATH1}
            className={classnames(styles.path1, styles['path1--anim'])}
            mask='url(#mask1)'
            filter='url(#fp1)'
          />
          <circle
            cx='0'
            cy='0'
            r='11.7308'
            className={styles.circle1}
            shapeRendering='crispEdges'
            filter='url(#fp1)'
          >
            <animateMotion
              dur='5s'
              keyPoints='0;1'
              keyTimes='0;1'
              calcMode='linear'
              repeatCount='indefinite'
            >
              <mpath xlinkHref='#path1' />
            </animateMotion>
          </circle>
        </g>
        <g className={styles.g2}>
          <path d={PATH2} className={styles.path2} id='path2' />
          <path
            d={PATH2}
            className={classnames(styles.path2, styles['path2--anim'])}
            mask='url(#mask2)'
            filter='url(#fp2)'
          />
          <circle
            cx='0'
            cy='0'
            r='11.7308'
            className={styles.circle2}
            shapeRendering='crispEdges'
            filter='url(#fp2)'
          >
            <animateMotion
              dur='5s'
              keyPoints='0;1'
              keyTimes='0;1'
              calcMode='linear'
              repeatCount='indefinite'
            >
              <mpath xlinkHref='#path2' />
            </animateMotion>
          </circle>
        </g>
      </svg>
    </div>
  );
};
