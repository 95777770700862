import classnames from 'classnames';
import { FC, useCallback } from 'react';

import { scrollToView } from '../../../utils/scrollToView';
import { useChangeActiveDiagram } from '../activeDiagramContext/activeDiagramContext';
import styles from './aboutSlide.module.scss';
import { ReactComponent as BIcon } from './bitcoin.svg';
import { ReactComponent as ButtonIcon } from './buttonIcon.svg';
import { ReactComponent as LIcon } from './litecoin.svg';
import { ReactComponent as TIcon } from './tether.svg';

export const AboutSlide: FC = () => {
  const changeDiagram = useChangeActiveDiagram();
  const onClick = useCallback(() => {
    changeDiagram('SM');
    scrollToView('slide_diagrams');
  }, [changeDiagram]);
  return (
    <div className={styles.slide}>
      <h2 className={classnames('h2', styles.slide__header)} id='slide_about'>
        <div className={styles.icons}>
          <TIcon />
          <BIcon />
          <LIcon />
        </div>
        <div>What is CryptoCortex?</div>
      </h2>
      <div className={styles.slide__text}>
      CryptoCortex is a Deltix technology.
      </div>
      <div className={styles.slide__text}>
      Deltix leverages over 10 years of experience in building, deploying, and supporting of institutional-grade intelligent trading across equities, futures, options, forex, and fixed income. We have applied all our knowledge, expertise, and technology to put together the advanced institutional cryptocurrency trading platform — CryptoCortex. It is a full stack solution designed to meet the essential needs of broker/dealers, exchanges, and buy side trading firms.
      </div>
      <div className={styles.slide__btn}>
        <button
          type='button'
          className='btn btn-secondary py-2'
          onClick={onClick}
        >
          <ButtonIcon />
          <span>Secondary Markets Scheme</span>
        </button>
      </div>
    </div>
  );
};
