import 'react-medium-image-zoom/dist/styles.css';

import { ComponentProps, FC, PropsWithChildren, useEffect } from 'react';
import ZoomComp from 'react-medium-image-zoom';

import styles from './zoom.module.scss';

export const Zoom: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    return () => {
      globalThis.document.body.style.width = '';
      globalThis.document.body.style.overflow = '';
    };
  }, []);

  return (
    <ZoomComp classDialog={styles.dialog} zoomMargin={16}>
      {children}
    </ZoomComp>
  );
};

export const withZoom =
  <T extends FC, P extends ComponentProps<T>>(Comp: FC<P>) =>
  (props: P) =>
    (
      <Zoom>
        <Comp {...props} />
      </Zoom>
    );
