import classnames from 'classnames';
import { createElement, FC, ReactElement, SVGProps } from 'react';
import 'swiper/swiper.min.css';
import { useWindowResize } from '../../../utils/hooks/customHooks';

import { BackToProducts } from '../../backToProducts/backToProducts';
import { Zoom } from '../../zoom/zoom';
import {
  Diagram,
  useActiveDiagram,
  useChangeActiveDiagram,
} from '../activeDiagramContext/activeDiagramContext';
import { MobileSlider } from '../mobileSlider/mobileSlider';
import axaImg from './AXA.png';
import BESvg from './be.svg';
import styles from './diagramsSlide.module.scss';
import EESvg from './ee.svg';
import FixSvg from './fix.svg';
import MMSvg from './mm.svg';
import NFTSvg from './nft.svg';
import OtcSvg from './otc.svg';
import SMSvg from './sm.svg';
import STSvg from './st.svg';

export interface IDiagram {
  type: Diagram;
  title: string;
  name: string;
  svg?: FC<SVGProps<SVGSVGElement>>;
  image?: string;
  text?: string | (string | string[])[] | ReactElement;
}

const DIAGRAMS: IDiagram[] = [
  {
    type: 'SM',
    name: 'Secondary Markets',
    title: 'CryptoCortex for Secondary Markets',
    image: SMSvg,
    text: 'CryptoCortex Secondary Markets Solution is a complete ecosystem for trading on secondary markets. It includes a FIX gateway for market data and trade orders, execution algorithms such as matching engines and trade simulators, a Pricing Engine to supply traders with customized market data feeds, and powerful web applications for traders and administrators.',
  },
  {
    type: 'BE',
    name: 'Broker Edition',
    title: 'CryptoCortex for Brokers',
    image: BESvg,
    text: [
      'Creation and streaming of a consolidated order book from multiple exchanges via FIX, web sockets, and REST interfaces',
      'Flexible Pricing Engine with the ability to define custom price levels and multiple price tiers',
      'Advanced execution management system (EMS) with internal matching engine (ECN) and FIX order flow interfaces',
      'Smart Order Routing (SOR)',
      'Algo Execution',
      'Ability to develop, back-test, and optimize custom SOR and execution algorithms',
      'Post-trade transaction cost analysis system (TCA)',
      'Flexible pre-trade risk management',
      'Powerful CRM system with advanced account configuration and management',
      `Integration with crypto wallet management systems`,
      'Enterprise-grade data warehouse for storing and streaming of historical prices',
      // 'Advanced reporting and trade surveillance system (to be developed)',
    ],
  },
  {
    type: 'NFT',
    name: 'NFT Edition',
    title: 'CryptoCortex NFT Edition',
    image: NFTSvg,
    text: (
      <p>
        CryptoCortex NFT Edition supports both centralized and decentralized
        types of NFT marketplaces.
        <br />
        <br />
        CryptoCortex solution for centralized NFT marketplaces is our standard
        ecosystem for exchanges with specialized user interface and auction
        algorithms. Refer to Exchange Edition for details.
        <br />
        <br />
        CryptoCortex solution for decentralized NFT marketplaces, on the other
        hand, is based on the technology that collects information from
        Blockchain in a centralized database, which can then be selectively
        replicated to local setups. Our web application can be integrated with
        third-party wallets (for example MetaMask) to work with your NFT
        collections.
      </p>
    ),
  },
  {
    type: 'EE',
    name: 'Exchange Edition',
    title: 'CryptoCortex for Exchanges',
    image: EESvg,
    text: [
      'Institutional-grade interfaces for streaming market data and receiving order flow:',
      [
        'FIX and multi-cast interfaces: market-by-order (Level 3) and market-by-level (Level 2)',
        'Web Sockets and REST APIs',
      ],
      'Co-located multi-cast and FIX interfaces for advanced HFT and market makers',
      'Enterprise-grade time series data warehouse with the ability to publish and distribute historical' +
        ' prices via streaming APIs in modern languages including Python, C++, C#, Java, R, and MatLab',
      'Ultra-low latency matching engine with the ability to implement custom matching algorithms and order types',
      'Ability to add custom exchange-hosted execution algorithms',
      'Transaction cost analysis system (TCA)',
      // 'Advanced trade surveillance and reporting systems (to be developed)',
      'Full-scale exchange simulator and UAT environment for paper-trading and FIX certifications',
      'Audit and risk management',
      'Integrations with external settlement systems',
      'Proprietary cloud-based state-of-art research and strategy development infrastructure for quants, robotic-traders, and market makers ',
    ],
  },
  {
    type: 'FIX',
    name: 'FIX Hub - Data & Trading',
    title: 'FIX Hub',
    image: FixSvg,
    text: `FIX Hub uses a proprietary FIX Gateway to connect with trading venues and process trade orders and market data. It includes connectors to over 50 digital asset trading venues, thus supporting spot, crypto, futures, and perpetuals assets. In addition to FIX, it provides REST and web socket (WS) APIs. FIX Hub maintains the history of orders, executions, and market data.`,
  },
  {
    type: 'SOR',
    name: 'Smart Order Router',
    title: 'Smart Order Router',
    text: (
      <p>
        Smart order router (SOR) is an algorithm that follows a set of rules to
        search for liquidity on different trading venues and facilitate the most
        profitable execution of orders. To do that, SOR creates passive or
        aggressive execution plan for each order, which defines prices and
        quantities for each of the configured trading venues.
        <br />
        <br />
        In case of an aggressive execution, the source liquidity is distributed
        by applying exchange-specific fees to all quotes/orders. Quotes from
        different venues that provide equal commission-adjusted price get sorted
        once again using other factors such as available liquidity. In the
        second pass, SOR splits the residual order quantity across all eligible
        venues by a passive price. The optimal plan is built considering
        fees/rebates for a passive execution and current market
        liquidity/turnover estimates for each venue.
      </p>
    ),
  },
  {
    type: 'OTC',
    name: 'OTC Desk',
    title: 'OTC Desk Edition',
    image: OtcSvg,
    text: `CryptoCortex OTC Desk Edition is an e-commerce solution for OTC Desks, allowing to offer order executions via streaming prices or RFQ
    (Request for Quote). OTC Desks act as principals, while sophisticated hedging capabilities allow hedging trades on a trade-by-trade
    basis, risk positions to accumulate on specified levels, and customer-specific spreads/mark-ups to be configured in addition to credit risk limits.`,
  },
  {
    type: 'TCA',
    name: 'Transaction Cost Analysis',
    title: 'Transaction Cost Analysis',
    image: axaImg,
    text: `Transaction Cost Analysis (TCA) is a service for advanced real-time analytics of algorithmic orders. It allows you to measure execution costs against various benchmarks (e.g. arrival price, VWAP) to compare the performance of different execution strategies. TCA service includes a rich gallery of charts, visualizations, and reports, which can be downloaded in CSV and PDF formats.`,
  },
  {
    type: 'MM',
    name: 'Market Maker',
    title: 'Market Maker',
    image: MMSvg,
    text: (
      <p>
        <a
          href='https://marketmaker.cloud?utm_campaign=landing&utm_source=cryptocortex.io'
          className='content-link'
          target='_blank'
          rel='noreferrer'
        >
          Market Maker
        </a>{' '}
        is a product for market makers and price arbitrageurs. It offers a
        powerful web-based functionality to create, configure, and run trading
        algorithms (bots), perform market-making and/or pricing arbitrage,
        including simple linear and triangular cases. Market Maker has
        connectors to dozens of exchanges and liquidity pools that generate real
        trade volume, a highly customizable hedging and risk limits configurator
        to satisfy even the most demanding logic, a flexible pricing engine to
        control quotes. Along with web-based tools, Market Maker offers REST/WS
        APIs for programmatic management of trading bots and risk rules
        on-the-fly.
        <br />
        <br />
        Market Maker solution includes auxiliary applications for
        administration, configuration, reporting, and system monitoring.
        <br />
        <br />
        Market Maker ecosystem is closely integrated with{' '}
        <a
          href='https://timebase.info?utm_campaign=landing&utm_source=cryptocortex.io'
          className='content-link'
          target='_blank'
          rel='noreferrer'
        >
          TimeBase
        </a>{' '}
        - a powerful high-performance time-series database and streaming
        service. TimeBase accumulates market data supplied by Aggregator. Trade
        orders are processed by a powerful EMS system, that includes SOR and
        other order execution algorithms.
        {/* is the main component of the Market Maker solution. You can use it to create, configure, and run trading algorithms (bots), run market-making and/or pricing arbitrage including simple linear and triangular cases. Market Maker includes  monitor trading and execution activity, configure risk and hedging, get reports and alerts, and much more.
        <br />
        <br />
        Market Maker ecosystem is closely integrated with <a
          href='https://timebase.info?utm_campaign=landing&utm_source=cryptocortex.io'
          className='content-link'
        >
          TimeBase
        </a>{' '}
        - a powerful high-performance time-series database and streaming
        service. TimeBase accumulates market data supplied by Aggregator. Trade orders are processed by a powerful EMS system, that includes SOR and other order execution algorithms.
        <br />
        <br />
        Market Maker solution includes auxiliary
        applications for administration, configuration, reporting, and
        system monitoring. 
        
        Configurator Web application allows setting up trading
        instruments, market data and trade connectors to various trading venues,
        configuring notifications and checking your account balances. 
        
        TimeBase Admin Web Client helps you to manage and monitor all data stored within
        the TimeBase database. Here you can monitor historical and live data,
        view and manage data streams and many more. 
        
        Ember Monitor allows to monitor system telemetries, regular and algo orders executions, and
        existing trading sessions. You can also use it to configure custom risk
        rules at a strategy level and much more. */}
      </p>
    ),
  },
  {
    type: 'ST',
    name: 'Spread Trader',
    title: 'Spread Trader',
    image: STSvg,
    text: (
      <p>
        <a
          href='https://spreadtrader.cloud?utm_campaign=landing&utm_source=cryptocortex.io'
          className='content-link'
          target='_blank'
          rel='noreferrer'
        >
          Spread Trader
        </a>{' '}
        is a professional solution for institutional traders and arbitrageurs
        allowing the creation and execution of custom spread trading strategies
        of any complexity. With Spread Trader, you can create, configure, run,
        and monitor exchange traded synthetic instruments from a single web
        application. Enjoy the flexibility of creating multi-leg and
        cross-exchange spreads, allowing implementing any spread trading logic
        of your choice.
      </p>
    ),
  },
];

const Text: FC<{ text: (string | string[])[] }> = ({ text }) => {
  return (
    <div className={styles.content__list}>
      {text.map((text, i) =>
        Array.isArray(text) ? (
          <Text key={i} text={text} />
        ) : (
          <div key={i} className={styles.content__list_item}>
            {text}
          </div>
        ),
      )}
    </div>
  );
};

export const ItemText: FC<{
  text?: string | (string | string[])[] | ReactElement;
}> = ({ text }) => {
  return text == null ? null : Array.isArray(text) ? (
    <Text text={text} />
  ) : (
    <div className={styles.content__text}>{text}</div>
  );
};

export const DiagramsSlide: FC = () => {
  const selected = useActiveDiagram();
  const onChange = useChangeActiveDiagram();

  const width = useWindowResize();

  return (
    <div className={styles.slide}>
      <h2 className={classnames('h2', styles.slide__h2)} id='slide_diagrams'>
        {' '}
      </h2>

      <div className={styles.diagrams}>
        {width && width < 768 ? (
          <MobileSlider diagrams={DIAGRAMS} selectedType={selected} />
        ) : (
          <>
            <div className={styles.diagrams__menu}>
              {DIAGRAMS.map((item) => (
                <div
                  key={item.type}
                  className={classnames(
                    styles.diagrams__menu_item,
                    selected === item.type &&
                      styles['diagrams__menu_item--active'],
                  )}
                  onClick={() => onChange(item.type)}
                >
                  {item.name}
                </div>
              ))}
            </div>
            <div className={styles.diagrams__content}>
              {DIAGRAMS.map((item) => (
                <div
                  key={item.type}
                  className={classnames(
                    styles.diagrams__content_item,
                    selected === item.type &&
                      styles['diagrams__content_item--active'],
                  )}
                >
                  <div
                    className={classnames(
                      styles.content,
                      styles[`content__type_${item.type}`],
                    )}
                  >
                    <h3 className={styles.content__header}>{item.title}</h3>
                    {item.svg &&
                      createElement(item.svg, {
                        className: styles.content__svg,
                      })}
                    {item.image && (
                      <Zoom>
                        <img
                          src={item.image}
                          className={styles.content__img}
                          alt={item.name}
                        />
                      </Zoom>
                    )}
                    <ItemText text={item.text} />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.fab}>
        <BackToProducts />
      </div>
    </div>
  );
};
