import classnames from 'classnames';
import { FC, MouseEventHandler, useCallback } from 'react';

import { scrollToView } from '../../../utils/scrollToView';
import { ScrollDown } from '../../scrollDown/scrollDown';
import { MainAnimation } from './mainAnimation';
import styles from './mainSlide.module.scss';
import { ReactComponent as Logo } from './text_logo.svg';

export const MainSlide: FC = () => {
  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
    scrollToView('slide_about');
  }, []);

  return (
    <div className={classnames('slide', styles.slide)}>
      <div className={styles.slide__info}>
        <Logo className={styles.logo} />
        <div className={styles.text}>
          The most advanced platform for primary and secondary markets of
          digital assets and cryptocurrencies
        </div>
        <div className={styles.btn}>
          <button
            className='btn btn-primary text-base py-2 mx-auto w-full tablet:w-auto'
            type='button'
            onClick={onClick}
          >
            About Platform
          </button>
        </div>
      </div>
      <div className={styles.slide__anim}>
        <MainAnimation />
      </div>
      <ScrollDown />
    </div>
  );
};
