import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CloseCvg } from './close.svg';
import { ReactComponent as HamburgerSvg } from './hamburger.svg';
import styles from './mobileMenu.module.scss';

export const MobileMenu: FC = () => {
  const [show, setShow] = useState(false);

  const toggle = useCallback(() => {
    setShow((flag) => !flag);
  }, []);

  useEffect(() => {
    const doc = globalThis.document.documentElement;
    if (show) {
      doc.classList.add(styles['no-scroll']);
    } else {
      doc.classList.remove(styles['no-scroll']);
    }
  }, [show]);

  return (
    <>
      <div className={styles['button']} onClick={toggle}>
        {show ? (
          <CloseCvg className={styles['icon']} />
        ) : (
          <HamburgerSvg className={styles['icon']} />
        )}
      </div>
      <div className={classNames(styles['menu'], show && styles['menu--show'])}>
        <div
          className={classNames(
            styles.mobile_menu_links,
            show && styles['menu--show_links'],
          )}
        >
          <Link onClick={toggle} className='link' to='/'>
            Home
          </Link>
          <Link onClick={toggle} className='link' to='/architecture'>
            Architecture
          </Link>
          <a
            onClick={toggle}
            className='link'
            href='https://kb.cryptocortex.io?utm_campaign=landing&utm_source=cryptocortex.io'
            target='_blank'
            rel='noreferrer'
          >
            Docs
          </a>
        </div>
        <div
          className={classNames(
            styles.mobile_menu_join,
            show && styles['menu--show_join'],
          )}
        >
          <a
            onClick={toggle}
            className='btn btn-outline'
            href='https://trade.cryptocortex.io/sign-in?utm_source=cryptocortex.io&utm_campaign=landing&utm_content=signOn'
            target='_blank'
            rel='noreferrer'
          >
            Join
          </a>
        </div>
      </div>
    </>
  );
};
