import classnames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo_dark.svg';
import { useWindowResize } from '../../utils/hooks/customHooks';
import styles from './header.module.scss';
import { MobileMenu } from './mobileMenu';

export const Header: FC = () => {
  const width = useWindowResize();
  return (
    <header className={classnames('content', styles.header)}>
      <div className={styles.header__logo}>
        <Link to='/' className={styles.logo}>
          <img src={logo} alt='CryptoCortex' />
        </Link>
      </div>
      {width && width < 769 ? (
        <MobileMenu />
      ) : (
        <>
          <div className={styles.header__links}>
            <Link className='link' to='/'>
              Home
            </Link>
            <Link className='link' to='/architecture'>
              Architecture
            </Link>
            <a
              className='link'
              href='https://kb.cryptocortex.io?utm_campaign=landing&utm_source=cryptocortex.io'
              target='_blank'
              rel='noreferrer'
            >
              Docs
            </a>
          </div>
          <div className={styles.header__join}>
            <a
              className='btn btn-outline'
              href='https://trade.cryptocortex.io/sign-in?utm_source=cryptocortex.io&utm_campaign=landing&utm_content=signOn'
              target='_blank'
              rel='noreferrer'
            >
              Join
            </a>
          </div>
        </>
      )}
    </header>
  );
};
