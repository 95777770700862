import classnames from 'classnames';
import { FC, useCallback } from 'react';

import { scrollToView } from '../../utils/scrollToView';
import styles from './scrollDown.module.scss';

export const ScrollDown: FC = () => {
  const onClick = useCallback(() => {
    scrollToView('slide_about');
  }, []);

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={classnames(styles.arrow, styles.arrow__text)}>Scroll Down</div>
      <svg
        width='18'
        height='22'
        viewBox='0 0 18 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1 1L8.29193 8.29285C8.68243 8.6834 9.31559 8.68344 9.70614 8.29294L17 1'
          strokeWidth='1.5'
          strokeLinecap='round'
          className={classnames(styles.arrow, styles.arrow__1)}
        />
        <path
          d='M1 13L8.29193 20.2928C8.68243 20.6834 9.31559 20.6834 9.70614 20.2929L17 13'
          strokeWidth='1.5'
          strokeLinecap='round'
          className={classnames(styles.arrow, styles.arrow__2)}
        />
      </svg>
    </div>
  );
};
