import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export type Diagram =
  | 'BE'
  | 'EE'
  | 'NFT'
  | 'OTC'
  | 'ST'
  | 'FIX'
  | 'TCA'
  | 'MM'
  | 'SOR'
  | 'SM';

const ActiveDiagramContext = createContext<Diagram>(void 0 as any);
const ChangeActiveDiagramContext = createContext<(v: Diagram) => unknown>(
  void 0 as any
);

export const ActiveDiagramProvider: FC<PropsWithChildren> = ({ children }) => {
  const [value, setValue] = useState<Diagram>('SM');

  return (
    <ActiveDiagramContext.Provider value={value}>
      <ChangeActiveDiagramContext.Provider value={setValue}>
        {children}
      </ChangeActiveDiagramContext.Provider>
    </ActiveDiagramContext.Provider>
  );
};

export const useActiveDiagram = () => {
  const value = useContext(ActiveDiagramContext);

  return value;
};

export const useChangeActiveDiagram = () => {
  const value = useContext(ChangeActiveDiagramContext);

  return value;
};
