import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { scrollToView } from '../../utils/scrollToView';
import { AboutSlide } from './aboutSlide/aboutSlide';
import { ActiveDiagramProvider } from './activeDiagramContext/activeDiagramContext';
import { DiagramsSlide } from './diagramsSlide/diagramsSlide';
import { MainSlide } from './mainSlide/mainSlide';
import { ProductsSlide } from './productsSlide/productsSlide';

export const Home: FC = () => {
  const nav = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.slide) {
      scrollToView(location.state.slide);
      nav(location.pathname, {
        ...location,
        state: void 0,
        replace: true,
      });
    }
  }, [nav, location]);
  return (
    <ActiveDiagramProvider>
      <div>
        <MainSlide />
        <AboutSlide />
        <ProductsSlide />
        <DiagramsSlide />
      </div>
    </ActiveDiagramProvider>
  );
};
