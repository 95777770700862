import './styles/index.scss';

import { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Footer } from './components/footer/footer';
import { Header } from './components/header/header';
import { Home } from './components/home/home';

const ArchitecturePage = lazy(
  () => import('./components/architecturePage/architecturePage'),
);

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <Header />
        <div className='content-container'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/architecture' element={<ArchitecturePage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
