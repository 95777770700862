import classnames from 'classnames';
import { FC, ReactElement } from 'react';

import { ReactComponent as DeltixLogo } from './deltix.svg';
import styles from './footer.module.scss';

import { ReactComponent as GlobeIcon } from './globe.svg';
import { ReactComponent as Globe2Icon } from './globe2.svg';
import { ReactComponent as MailIcon } from './mail.svg';

const year = new Date().getFullYear();

const FooterItem: FC<{
  children: [ReactElement, ReactElement, ReactElement];
}> = ({ children: [icon, label, text] }) => {
  return (
    <div className={styles.item}>
      <div className={styles.item__icon}>{icon}</div>
      <div className={styles.item__info}>
        <div className={styles.item__info_label}>{label}</div>
        <div className={styles.item__info_value}>{text}</div>
      </div>
    </div>
  );
};

export const Footer: FC = () => {
  return (
    <footer className={classnames('content', styles.footer)}>
      <div className={styles.footer__logo}>
        <a href='https://www.deltixlab.com' target='_blank' rel='noreferrer'>
          <DeltixLogo />
        </a>
      </div>
      <div className={styles.footer__contacts}>
        <FooterItem>
          <GlobeIcon />
          <>Contacts for Americas:</>
          <>+1-267-7599000 ext. 32879</>
        </FooterItem>
        <FooterItem>
          <Globe2Icon />
          <>Contacts for Europe & APAC:</>
          <>+44-203-5140027 ext. 36127</>
        </FooterItem>
        <FooterItem>
          <MailIcon />
          <>Email:</>
          <a href='mailto:sales@deltixlab.com'>sales@deltixlab.com</a>
        </FooterItem>
      </div>
      <div className={styles.footer__cr}>
        © {year} Deltix. All rights reserved
        <br/>
        "Cortex is a registered trademark of BNP Paribas S.A. CryptoCortex is neither affiliated with nor endorsed by BNP Paribas."
      </div>
    </footer>
  );
};
